<template>
   <div>
       <el-row> 
          <el-col :span="24" style="text-align:right; ">
             <el-button type="primary" @click="save">保存</el-button>
             <el-button type="primary" @click="goBack">返回</el-button>
          </el-col>
       </el-row>  
       <div style="border-bottom:1px solid #ddd;height:10px;"></div> 
       <el-row style="padding-top:20px;">
          <el-form>
             <el-col :span="7">
                 <el-form-item label="排序：" label-width="90px">
                    <el-input v-model="seq"></el-input>
                 </el-form-item>
             </el-col>
             <el-col :span="18">
                <el-form-item label="内容：" label-width="90px">
                 <quill-editor
                    ref="myQuillEditor"
                    :options="editorOption"
                    v-model="content"
                    class="myQuillEditor yunmu-ql-editor"
                    style="white-space:pre-wrap;"
                    />
                </el-form-item>
             </el-col>
             <el-col :span="18">
                <el-form-item label="上传图片：" label-width="90px">
                    <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="false"
                          :on-success="handleAvatarSuccess" >
                          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                </el-form-item>
             </el-col>
          </el-form> 
       </el-row>
    <el-upload  v-show="false" :action="$uploadURL" :show-file-list="false"
         :on-success="quillSuccess" >
        <el-button size="small" type="primary" id="quill-image">点击上传</el-button>
    </el-upload> 
   </div> 
</template>
<script>
import { quillEditor } from "vue-quill-editor";
export default {
 components: {
    quillEditor
  },
    data(){
        return {
           id:'',
           seq:'',
           content:'',
           imageUrl:'',
               // 富文本编辑器配置
    editorOption: {
        modules: {
          toolbar:{
            container:[
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            // [{ font: ['songti'] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ['clean'], // 清除文本格式
            ['image', 'video'] // 链接、图片、视频
          ],
        // },
        handlers:{
          image:function(value){
            if(value){
              //调用elementUi上传组件替换富文本原生图片上传
              document.getElementById('quill-image').click();
            }else{
              this.quill.format('image',false)
            }
          }
        }
      }
      },
    },
        }
    },
    created(){
      this.id = this.$route.query.id
      if (this.id) {
         this.getDetails()
      }
    },
    methods:{
        getDetails(){
           let data = {}
           data.id = this.id
           this.$http.post('/platformBanner/queryOne',data).then(res => {
             if(res.data.code == 200) {
                  this.seq = res.data.data.seq
                  this.content = res.data.data.imageName
                  this.imageUrl = res.data.data.imageUrl
              }
           })
        },
        goBack(){
           this.$router.go(-1)
        },
        save(){
           let data = {}
           data.imageUrl = this.imageUrl
            if (!data.imageUrl) {
             this.$notify.error({
                title: "提示",
                message: "请上传轮播图",
             });
             return
           }
           data.seq = this.seq
           if (!data.seq) {
             this.$notify.error({
                title: "提示",
                message: "请输入排序",
             });
             return
           }
           data.imageName = this.content
           data.id = this.id
           this.$http.post('/platformBanner/save',data).then(res => {
             if(res.data.code == 200) {
                this.$notify.success({
                 title: "提示",
                 message: "保存成功",
                });
                this.$router.go(-1)
              } else {
                 this.$notify.success({
                 title: "提示",
                 message: res.data.message,
                });
              }
           })
        },
        quillSuccess(res, file) { 
        console.log('上传');
        let quill=this.$refs.myQuillEditor.quill
        let length=quill.getSelection().index
        quill.insertEmbed(length,"image",file.response.data.url)
        quill.setSelection(length+1)
        },
        handleAvatarSuccess(res, file) {
        this.imageUrl = file.response.data.url;
        },
    }
}
</script>

<style lang="less" scoped>
  //上传封面样式
.avatar-uploader /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader /deep/ .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>